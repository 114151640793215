import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { DigitalLectureFilterItemModel } from '~/model/DigitalLecture/index'

export default class DigitalLectureFiltersModel extends BaseModel {
  categories: DigitalLectureFilterItemModel = new DigitalLectureFilterItemModel(null)
  popularTags: DigitalLectureFilterItemModel = new DigitalLectureFilterItemModel(null)
  tags: DigitalLectureFilterItemModel = new DigitalLectureFilterItemModel(null)
  name: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
