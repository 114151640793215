import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class DigitalLectureDetailMaterialModel extends BaseModel {
  id: number = 0
  name: string = ''
  file: string = ''
  'file_type': string = ''
  'file_size': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
