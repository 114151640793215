import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { ImageService } from '~/services/Misc/ImageService'

export default class DigitalLectureDetailSpeakerModel extends BaseModel {
  id: number = 0
  name: string = ''
  'middle_name': string = ''
  'last_name': string = ''
  position: string = ''
  photo: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.photo) {
        this.photo = new ImageService().cropImage(this.photo, {})
      }
    }
  }
}
