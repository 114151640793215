import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import VideoCardModel from '~/model/Cards/VideoCardModel'

export default class DigitalLecturePageModel extends BaseModel {
  list: VideoCardModel[] = []
  total: number = 0

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
