import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { DigitalLectureFilterOptionModel } from '~/model/DigitalLecture/index'

export default class DigitalLectureFilterItemModel extends BaseModel {
  options: DigitalLectureFilterOptionModel[] = []
  values: any = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.options)) {
        this.options = data.options.map(i => new DigitalLectureFilterOptionModel(i))
      }
    }
  }
}
