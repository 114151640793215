interface ImageCropParams {
  mode?: 'exact' | 'portrait' | 'landscape' | 'auto' | 'crop'
  offset?: [number, number]
  extension?: 'auto' | 'jpg' | 'jpeg' | 'gif' | 'png'
  quality?: number
  sharpen?: number
  height?: number
  width?: number
}

export class ImageService {
  public cropImage(path, params: ImageCropParams): string {
    params = Object.assign(
      {
        quality: 90
      },
      params
    )
    let src = `/api/v1/yavagro/images/resize?url=${path}`
    for (const key in params) {
      src += `&${key}=${params[key]}`
    }
    return src
  }
}
