import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'
import { toDateFormatComma } from '~/shared/utils/date'

export default class VideoCardModel extends BaseModel {
  id: number = 0
  'category_id': number = 0
  'created_at': string = ''
  'date_publication': string = ''
  description: string = ''
  'is_published': boolean = false
  'likes_count': number = 0
  name: string = ''
  preview: string = ''
  'short_description': string = ''
  slug: string = ''
  'updated_at': string = ''
  'video_file': string = ''
  'video_link': string = ''
  time: string = ''
  'views_count': number = 0

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }

    if (this.preview) {
      this.preview = new ImageService().cropImage(this.preview, {})
    }

    if (this.date_publication) {
      this.date_publication = toDateFormatComma(this.date_publication)
    }
  }
}
