/* eslint-disable @typescript-eslint/naming-convention */
export const COOKIE_MAX_AGE_HOUR = 3600 // 1 час
export const COOKIE_MAX_AGE_SECONDS = 28800 // 8 часов
export const COOKIE_PATH = '/'
export const COOKIE_TOKEN_KEY = 'token'
export const COOKIE_REFRESH_TOKEN_KEY = 'refresh_token'
export const COOKIE_UPDATE_REFRESH_TOKEN_KEY = 'update_refresh_token'
export const COOKIE_NEWS_DISLIKE_KEY = 'news_dislike'
export const COOKIE_NEWS_LIKE_KEY = 'news_like'
export const COOKIE_DIG_LECTURE_LIKE_KEY = 'digital_lecture_like'

export const COOKIE_UNIQUE_TOKEN = 'fwGgHFAI1Y7V5x17riwcL8JtFxuAq7BbF0PGtraQffRc2DlDbIdF3WgQVY4qHHPt7HuPZXLrLEZ5Dyo1'
export const COOKIE_UNIQUE_TOKEN_KEY = 'uniquePerson'

export const COOKIE_GEOLOCATION_MAX_AGE_SECONDS = 43200 // 12 часов
export const COOKIE_GEOLOCATION_TOKEN_KEY = 'region'

export const COOKIE_REGISTER_CAREER_MARATHON = 'careerMarathon'
export const COOKIE_REGISTER_CAREER_MARATHON_MAX_AGE_SECONDS = 4838400 // 48 дней
export const COOKIE_VACANCIES_RESPONSE = 'vacanciesResponse'
export const COOKIE_TWO_MONTH = 60 * 24 * 60
