import { ApiService } from '~/shared/api'
import { restCreate, sendForm } from '~/shared/utils/decorators'
import { ServiceCanSendForm, ServiceRestCreate } from '~/shared/interfaces'

interface EventScheduleFormService extends ServiceCanSendForm, ServiceRestCreate {}

@sendForm
@restCreate
// eslint-disable-next-line no-redeclare
class EventScheduleFormService extends ApiService {
  // eslint-disable-next-line no-template-curly-in-string
  protected route: string = 'activities/${slug}/feedback'
}

export default EventScheduleFormService
