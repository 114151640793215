import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { ImageService } from '~/services/Misc/ImageService'
import { EventScheduleActivityModel } from '~/model/EventSchedule/index'

export default class EventScheduleItemModel extends BaseModel {
  id: number = 0
  name: string = ''
  description: string = ''
  image: string = ''
  activities: EventScheduleActivityModel[] = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.image) {
        this.image = new ImageService().cropImage(data.image, {})
      }

      if (Array.isArray(data.activities)) {
        this.activities = data.activities.map(i => new EventScheduleActivityModel(i))
      }
    }
  }
}
