import { ApiService } from '~/shared/api'
import VideoCardModel from '~/model/Cards/VideoCardModel'

export default class DDigitalLectureRandomServiceService extends ApiService {
  readonly url = '/api/v1/yavagro/digital-lecture/videos/random'

  public async getDigitalLectureRandomVideo<T>(paramsQuery): Promise<Array<VideoCardModel>> {
    const res: any = await this.requestService.request<T>({
      url: this.url,
      params: paramsQuery
    })

    if (Array.isArray(res.data.data)) {
      return res.data.data.map(i => new VideoCardModel(i))
    }

    return []
  }
}
