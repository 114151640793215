import { checkedRule, emailRule, requiredRule } from '~/shared/utils/validation'
import { Form } from '~/shared/api'
import type { UserModel } from '~/model/User'

export default class EventScheduleForm extends Form {
  'company_name': string = ''
  phone: string = ''
  email: string = ''
  'contact_person': string = ''
  isAgree: boolean = false

  constructor(user: UserModel) {
    super()
    this.fillForm(user)
  }

  fillForm(user: UserModel) {
    this.contact_person = user.name
    this.company_name = user.sf_company.name
    this.email = user.email
    this.phone = user.phone
  }

  clear() {
    this.company_name = ''
    this.email = ''
    this.contact_person = ''
    this.phone = ''
    this.isAgree = false
  }

  static rules() {
    return {
      company_name: requiredRule,
      email: { ...requiredRule, ...emailRule },
      contact_person: requiredRule,
      phone: requiredRule,
      isAgree: checkedRule
    }
  }
}
