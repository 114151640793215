import { ApiService } from '~/shared/api'
import { restGetList, restGetItem } from '~/shared/utils/decorators'
import { RequestService } from '~/services/RequestService'
import { DigitalLectureFiltersService, DigitalLectureRandomService } from '~/services/DigitalLecture/index'
import type { ServiceRestGetItem, ServiceRestGetList } from '~/shared/interfaces'
import VideoCardModel from '~/model/Cards/VideoCardModel'
import { isUnknownObject } from '~/shared/utils/guards'
import { DigitalLectureDetailModel, DigitalLecturePageModel } from '~/model/DigitalLecture'

// eslint-disable-next-line
interface DigitalLectureService extends ServiceRestGetList, ServiceRestGetItem {}

// @ts-ignore
@restGetList
@restGetItem

// eslint-disable-next-line no-redeclare
class DigitalLectureService extends ApiService {
  protected route: string = 'digital-lecture/videos'

  filtersService: DigitalLectureFiltersService
  randomService: DigitalLectureRandomService

  constructor(requestService: RequestService) {
    super(requestService)
    this.filtersService = new DigitalLectureFiltersService(requestService)
    this.randomService = new DigitalLectureRandomService(requestService)
  }

  getListModel(params): Promise<DigitalLecturePageModel> {
    return this.getList(params).then(({ data, total }) => {
      if (Array.isArray(data)) {
        const list = data.map(i => new VideoCardModel(i))
        return new DigitalLecturePageModel({ list, total })
      }

      return new DigitalLecturePageModel(null)
    })
  }

  getItemModel(slug: string): Promise<DigitalLectureDetailModel> {
    return this.getItem(slug)
      .then(({ data }) => {
        const raw = isUnknownObject(data) && isUnknownObject(data.data) ? data.data : null
        return new DigitalLectureDetailModel(raw)
      })
      .catch(() => new DigitalLectureDetailModel(null))
  }

  public async addCountViews<T>(slug: string): Promise<T> {
    const url = `/api/v1/yavagro/digital-lecture/videos/${slug}/add-view`
    const res: any = await this.requestService.request<T>({
      method: 'POST',
      url
    })
    return res.data
  }

  public async addLike<T>(slug: string): Promise<T> {
    const url = `/api/v1/yavagro/digital-lecture/videos/${slug}/add-like`
    const res: any = await this.requestService.request<T>({
      method: 'POST',
      url
    })
    return res.data
  }
}

export default DigitalLectureService
