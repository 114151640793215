import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { DigitalLectureDetailModel, DigitalLectureFiltersModel } from '~/model/DigitalLecture'
import VideoCardModel from '~/model/Cards/VideoCardModel'
import { COOKIE_DIG_LECTURE_LIKE_KEY, COOKIE_MAX_AGE_SECONDS, COOKIE_PATH } from '~/shared/const'

@Module({ namespaced: true, stateFactory: true, name: 'digitalLecture' })
export default class extends VuexModule {
  private videoList: Array<VideoCardModel> = []
  private videoRandomList: Array<VideoCardModel> = []
  private videoTotal: number = 0
  private videoItem: DigitalLectureDetailModel = new DigitalLectureDetailModel({})
  private digitalLectureFilters: DigitalLectureFiltersModel = new DigitalLectureFiltersModel({})
  private store: any

  @Mutation
  private mutationSetVideoList(videoList: VideoCardModel[]): void {
    this.videoList = videoList
  }

  @Mutation
  private mutationSetVideoRandomList(videoList: VideoCardModel[]): void {
    this.videoRandomList = videoList
  }

  @Mutation
  private mutationSetVideoItem(videoItem: DigitalLectureDetailModel): void {
    this.videoItem = videoItem
  }

  @Mutation
  private mutationSetFilters(filters: DigitalLectureFiltersModel): void {
    this.digitalLectureFilters = filters
  }

  @Mutation
  private mutationTotal(total: number): void {
    this.videoTotal = total
  }

  @Action
  public setVideoList(list: VideoCardModel[]): void {
    this.context.commit('mutationSetVideoList', list)
  }

  @Action
  public setVideoRandomList(list: VideoCardModel[]): void {
    this.context.commit('mutationSetVideoRandomList', list)
  }

  @Action
  public setVideoItem(item: DigitalLectureDetailModel): void {
    this.context.commit('mutationSetVideoItem', item)
  }

  @Action
  public setFilters(filters: DigitalLectureFiltersModel): void {
    this.context.commit('mutationSetFilters', filters)
  }

  @Action
  public setTotal(total: number): void {
    this.context.commit('mutationTotal', total)
  }

  @Action
  public setLike(slug: string): void {
    const like = this.store.$cookies.get(COOKIE_DIG_LECTURE_LIKE_KEY) ?? []
    if (!like.includes(slug)) {
      like.push(slug)
      this.store.$cookies.set(COOKIE_DIG_LECTURE_LIKE_KEY, like, {
        path: COOKIE_PATH,
        maxAge: COOKIE_MAX_AGE_SECONDS * 1000
      })
      this.videoItem.is_liked = like.includes(slug)
      this.context.commit('mutationSetVideoItem', this.videoItem)
    }
  }

  @Action
  public getLike(slug: string) {
    const like = this.store.$cookies.get(COOKIE_DIG_LECTURE_LIKE_KEY) ?? []
    if (this.videoItem) {
      this.videoItem.is_liked = like.includes(slug)
      this.context.commit('mutationSetVideoItem', this.videoItem)
    }
  }

  get VideoList(): VideoCardModel[] {
    return this.videoList
  }

  get VideoRandomList(): VideoCardModel[] {
    return this.videoRandomList
  }

  get VideoItem(): DigitalLectureDetailModel {
    return this.videoItem
  }

  get LectureFilters(): DigitalLectureFiltersModel {
    return this.digitalLectureFilters
  }

  get CurrentVideoTotal(): number {
    return this.videoTotal
  }
}
