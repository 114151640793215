import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class DigitalLectureFilterOptionModel extends BaseModel {
  id: string | number = ''
  name: string = ''
  isActive?: boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
