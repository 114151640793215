import { Context } from '@nuxt/types'
import { CourseService } from './CourseService'
import { NewsService } from './Cards/NewsService'
import { RequestService } from './RequestService'
import { ProfessionsService } from './Cards/ProfessionsService'
import { WorkCardsService } from './Cards/WorkCardsService'
import { FutureProfService } from './Cards/FutureProfService'
import { TagCloudService } from './Misc/TagCloudService'
import { SubscribeBlockService } from './Misc/SubscribeBlockService'
import { DirectionService } from './DirectionService'
import { BookService } from './Cards/BookService'
import { BookSortingService } from './Cards/BookSortingService'
import { UniversitiesSortingService } from './Cards/UniversitiesSortingService'
import { CollegesSortingService } from './Cards/CollegesSortingService'
import { StatisticBlockService } from './Misc/StatisticBlockService'
import { SearchService } from './Misc/SearchService'
import { RequestRestService } from './RequestRestService'
import { QuantumService } from './Cards/QuantumService'
import { GrantsService } from './Cards/GrantsService'
import { GrantsSlidesService } from './Slides/GrantsSlidesService'
import { GrantsSortingService } from './Cards/GrantsSortingService'
import { GrantsFaqService } from './Cards/GrantsFaqService'
import { MapService } from './Misc/MapService'
import { SchoolDirectionsService } from './Cards/SchoolDirectionsService'
import { ImageService } from './Misc/ImageService'
import { MarathonService } from './MarathonService'
import { EventService } from './EventService'
import { CourseSortingService } from './Cards/CourseSortingService'
import { GeolocationService } from '~/services/GeolocationService'
import { EcosystemService } from '~/services/EcosystemService'
import { AmbassadorsService } from '~/services/AmbassadorsService'
import { PartnerService } from '~/services/PartnerService'
import { StudentsService } from '~/services/StudentsService'
import { DigitalProfileService } from '~/services/DigitalProfileService'
import { DadataService } from '~/services/DadataService'
import { SchoolchildrenService } from '~/services/SchoolchildrenService'
import { FilterService } from '~/services/FilterService'
import { UserService } from '~/services/User'
import { EmployerService } from '~/services/Employer'
import { PagesService } from '~/services/Pages'
/** Вывести из оборота NewsService и заменить его сервисом из /services/News */
import { NewsService as NewNewsService } from '~/services/News'
import { TestingService } from '~/services/Testing'
import { SubscribeService } from '~/services/Subscribe'
import { VacanciesService, VacancyService } from '~/services/Vacancies'
import { ResumesService } from '~/services/Resumes'
import { BreadCrumbsService } from '~/services/BreadCrumbs'
import { OlympiadService } from '~/services/Olympiad'
import { EmployersTaskService } from '~/services/EmployersTask'
import { AccountUniversityStatisticsService } from '~/services/AccountUniversityStatistics'
import { AccountResumesSubscriptionsService } from '~/services/AccountResumesSubscriptions'
import { ApplicationForBankProductFormService } from '~/services/ApplicationForBankProduct'
import { DigitalProfilesFiltersService } from '~/services/DigitalProfilesFiltersService'
import { StudentActivitiesFormService, StudentActivitiesQuantityService } from '~/services/StudentActivities'
import { ExecutiveSearchFormService } from '~/services/ExecutiveSearch'
import { LandingFormService, LandingService } from '~/services/Landing'
import { ApplicationPageCreationService } from '~/services/ApplicationPageCreation'
import { NewsRecommendationsService } from '~/services/NewsRecommendations'
import { CareerNavigatorService } from '~/services/CareerNavigator/CareerNavigatorService'
import { WomenAPKService } from '~/services/WomenAPK'
import { CareerRoutesService } from '~/services/CareerRoutes'
import { DigitalLectureService } from '~/services/DigitalLecture'
import { EventScheduleService } from '~/services/EventSchedule'

export interface Services {
  /**
   * @deprecated
   * Использовать $services.user вместо $services.auth
   */
  auth: UserService
  user: UserService
  course: CourseService
  /**
   * @deprecated
   * Использовать $services._news вместо $services.news
   */
  news: NewsService
  _news: NewNewsService
  image: ImageService
  request: RequestService
  professions: ProfessionsService
  workCards: WorkCardsService
  futureProf: FutureProfService
  tagcloud: TagCloudService
  subscribeBlock: SubscribeBlockService
  direction: DirectionService
  book: BookService
  bookSorting: BookSortingService
  universitiesSorting: UniversitiesSortingService
  collegesSorting: CollegesSortingService
  statisticBlock: StatisticBlockService
  search: SearchService
  quantums: QuantumService
  grants: GrantsService
  grantsSlides: GrantsSlidesService
  grantsSorting: GrantsSortingService
  grantsFaq: GrantsFaqService
  requestRest: RequestRestService
  map: MapService
  schoolDirections: SchoolDirectionsService
  subscribe: SubscribeService
  marathon: MarathonService
  event: EventService
  courseSorting: CourseSortingService
  geolocation: GeolocationService
  vacancies: VacanciesService
  breadcrumbs: BreadCrumbsService
  olympiad: OlympiadService
  ecosystem: EcosystemService
  ambassadors: AmbassadorsService
  partner: PartnerService
  students: StudentsService
  digitalProfile: DigitalProfileService
  dadata: DadataService
  schoolchildren: SchoolchildrenService
  filters: FilterService
  employer: EmployerService
  testing: TestingService
  pagesService: PagesService
  vacancy: VacancyService
  resumes: ResumesService
  employersTaskService: EmployersTaskService
  accountUniversityStatisticsService: AccountUniversityStatisticsService
  accountResumesSubscriptionsService: AccountResumesSubscriptionsService
  applicationForBankProductFormService: ApplicationForBankProductFormService
  digitalProfilesFiltersService: DigitalProfilesFiltersService
  studentActivitiesQuantityService: StudentActivitiesQuantityService
  studentActivitiesFormService: StudentActivitiesFormService
  executiveSearchFormService: ExecutiveSearchFormService
  landingService: LandingService
  landingFormService: LandingFormService
  applicationPageCreationService: ApplicationPageCreationService
  newsRecommendationsService: NewsRecommendationsService
  careerNavigatorService: CareerNavigatorService
  careerRoutesService: CareerRoutesService
  womenAPKService: WomenAPKService
  digitalLectureService: DigitalLectureService
  eventScheduleService: EventScheduleService
}

export const initializeServices: (context) => Services = (context: Context) => {
  const axios = context.$axios
  const cookies = context.$cookies
  const route = context.route
  const router = context.app.router
  const store = context.store
  const config = context.$config
  const tstore = context.$tstore
  const request = new RequestService(axios, tstore)
  const auth = new UserService(request, cookies, route, store, config)
  const course = new CourseService(request)
  const news = new NewsService(request)
  const _news = new NewNewsService(request); // eslint-disable-line
  const image = new ImageService()
  const professions = new ProfessionsService(request)
  const workCards = new WorkCardsService(request)
  const futureProf = new FutureProfService(request)
  const tagcloud = new TagCloudService(request)
  const subscribeBlock = new SubscribeBlockService(request)
  const direction = new DirectionService(request)
  const book = new BookService(request)
  const bookSorting = new BookSortingService(request)
  const universitiesSorting = new UniversitiesSortingService(request)
  const collegesSorting = new CollegesSortingService(request)
  const statisticBlock = new StatisticBlockService(request)
  const requestRest = new RequestRestService(request)
  const search = new SearchService(request)
  const quantums = new QuantumService(request)
  const grants = new GrantsService(request)
  const grantsSlides = new GrantsSlidesService(request)
  const grantsSorting = new GrantsSortingService(request)
  const grantsFaq = new GrantsFaqService(request)
  const map = new MapService(request)
  const schoolDirections = new SchoolDirectionsService(request)
  const subscribe = new SubscribeService(request)
  const marathon = new MarathonService(request)
  const event = new EventService(request)
  const courseSorting = new CourseSortingService(request)
  const geolocation = new GeolocationService(request, cookies)
  const vacancies = new VacanciesService(request, geolocation, cookies)
  const resumes = new ResumesService(request)
  const breadcrumbs = new BreadCrumbsService(request)
  const olympiad = new OlympiadService(request)
  const ecosystem = new EcosystemService(request)
  const ambassadors = new AmbassadorsService(request)
  const partner = new PartnerService(request)
  const students = new StudentsService(request)
  const digitalProfile = new DigitalProfileService(request)
  const dadata = new DadataService()
  const schoolchildren = new SchoolchildrenService(request)
  const filters = new FilterService(request, router, cookies, tstore, geolocation)
  const employer = new EmployerService(request)
  const testing = new TestingService(request)
  const pagesService = new PagesService(request)
  const vacancy = new VacancyService(request)
  const employersTaskService = new EmployersTaskService(request)
  const accountUniversityStatisticsService = new AccountUniversityStatisticsService(request)
  const accountResumesSubscriptionsService = new AccountResumesSubscriptionsService(request)
  const applicationForBankProductFormService = new ApplicationForBankProductFormService(request)
  const digitalProfilesFiltersService = new DigitalProfilesFiltersService(request)
  const studentActivitiesQuantityService = new StudentActivitiesQuantityService(request)
  const studentActivitiesFormService = new StudentActivitiesFormService(request)
  const executiveSearchFormService = new ExecutiveSearchFormService(request)
  const landingService = new LandingService(request)
  const landingFormService = new LandingFormService(request)
  const applicationPageCreationService = new ApplicationPageCreationService(request)
  const newsRecommendationsService = new NewsRecommendationsService(request)
  const careerNavigatorService = new CareerNavigatorService(request)
  const womenAPKService = new WomenAPKService(request)
  const careerRoutesService = new CareerRoutesService(request)
  const digitalLectureService = new DigitalLectureService(request)
  const eventScheduleService = new EventScheduleService(request)

  return {
    auth,
    user: auth,
    course,
    news,
    _news,
    image,
    request,
    professions,
    workCards,
    futureProf,
    tagcloud,
    subscribeBlock,
    direction,
    book,
    bookSorting,
    universitiesSorting,
    collegesSorting,
    statisticBlock,
    quantums,
    grants,
    grantsSlides,
    grantsSorting,
    grantsFaq,
    map,
    search,
    requestRest,
    schoolDirections,
    subscribe,
    marathon,
    event,
    courseSorting,
    geolocation,
    vacancies,
    resumes,
    breadcrumbs,
    ecosystem,
    ambassadors,
    partner,
    students,
    digitalProfile,
    dadata,
    schoolchildren,
    filters,
    employer,
    testing,
    pagesService,
    vacancy,
    olympiad,
    employersTaskService,
    accountUniversityStatisticsService,
    accountResumesSubscriptionsService,
    applicationForBankProductFormService,
    digitalProfilesFiltersService,
    studentActivitiesQuantityService,
    studentActivitiesFormService,
    executiveSearchFormService,
    landingService,
    landingFormService,
    applicationPageCreationService,
    newsRecommendationsService,
    careerNavigatorService,
    careerRoutesService,
    womenAPKService,
    digitalLectureService,
    eventScheduleService
  }
}
