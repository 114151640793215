import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { EventScheduleCompanyModel } from '~/model/EventSchedule/index'

export default class EventScheduleActivityModel extends BaseModel {
  id: number = 0
  name: string = ''
  description: string = ''
  'end_time': string = ''
  'start_time': string = ''
  date: string = ''
  link: string = ''
  'is_participant': boolean = false
  'is_feedback_sent': boolean = false
  employers: EventScheduleCompanyModel[] = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.employers)) {
        this.employers = data.employers.map(i => new EventScheduleCompanyModel(i))
      }
    }
  }
}
