import { ApiService } from '~/shared/api'
import { DigitalLectureFiltersModel } from '~/model/DigitalLecture'

export default class DigitalLectureFiltersService extends ApiService {
  readonly url = 'api/v1/yavagro/digital-lecture/filters'

  public async getDigitalLectureFilters<T>(queryParams) {
    const res: any = await this.requestService.request<T>({
      url: this.url,
      params: queryParams
    })

    return new DigitalLectureFiltersModel(res.data.data)
  }
}
