import { ApiService } from '~/shared/api'
import { restGetList } from '~/shared/utils/decorators'
import { QueryParams, ServiceRestGetList } from '~/shared/interfaces'
import { EventScheduleItemModel } from '~/model/EventSchedule'
import { RequestService } from '~/services/RequestService'
import { EventScheduleFormService } from '~/services/EventSchedule/index'

// eslint-disable-next-line
interface EventScheduleService extends ServiceRestGetList {}

// @ts-ignore
@restGetList

// eslint-disable-next-line no-redeclare
class EventScheduleService extends ApiService {
  protected route: string = 'activities'
  public eventScheduleFormService: EventScheduleFormService

  constructor(requestService: RequestService) {
    super(requestService)
    this.eventScheduleFormService = new EventScheduleFormService(requestService)
  }

  getListModel(params: QueryParams): Promise<Array<EventScheduleItemModel>> {
    return this.getList(params).then(({ data }) => {
      if (Array.isArray(data)) {
        return data.map(i => new EventScheduleItemModel(i))
      }

      return []
    })
  }
}

export default EventScheduleService
