
import BaseComponent from '@rshb/rshb-vue-kit/src/components/base/BaseComponent.vue'
import BaseBlock from '@rshb/rshb-vue-kit/src/components/grid/BaseBlock.vue'
import BaseIcon from '@rshb/rshb-vue-kit/src/components/icons/BaseIcon.vue'
import BaseFontXs from '@rshb/rshb-vue-kit/src/components/font/BaseFontXs'

export default {
  name: 'BaseYavagroToast',
  components: {
    BaseBlock,
    BaseIcon,
    BaseFontXs
  },
  extends: BaseComponent,
  props: {
    type: {
      type: String,
      validator: v => ['dark', 'loading', 'error', 'success'].includes(v),
      default: 'loading'
    },
    title: {
      type: String,
      default: undefined
    },
    start: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: undefined
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    iconAlignTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        [`base-rabota-yavagro-notice p--x-7 base-rabota-yavagro-notice--${this.type}`]: true,
        'grid--align-start': this.iconAlignTop,
        'grid--align-center': !this.iconAlignTop,
        'base-rabota-agro-theme-notice': this.start
      }
    },
    icon() {
      const icons = {
        dark: 'notify-info',
        loading: 'notify-info',
        error: 'notify-error',
        success: 'green-tick'
      }

      return icons[this.type]
    }
  },
  mounted() {
    if (this.start) {
      document.body.style.overflow = 'clip'
    }
  },
  beforeDestroy() {
    document.body.style.overflow = 'inherit'
  }
}
