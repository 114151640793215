
import { Component, Vue } from 'nuxt-property-decorator'
import { EcosystemDropdownMenuData, EcosystemMenuItem, EcosystemMenuItemUIKitFormat } from '~/model/EcosystemMenuItem'

@Component({
  components: {}
})
export default class HeaderEcosystem extends Vue {
  public isShowDropdownMenu = false
  public isShowMobileDropdownMenu = false

  public dropdownContent: EcosystemDropdownMenuData = {
    title: 'Экосистема',
    description: 'Технологии для развития сельского хозяйства в едином цифровом пространстве',
    anotherServices: {
      title: 'другие площадки экосистемы',
      description: '',
      content: []
    },
    popularServices: {
      title: 'популярное на я в агро',
      description: '',
      content: []
    }
  }

  protected setDropdownContent(otherServicesList: EcosystemMenuItem[], popularServicesList: EcosystemMenuItem[]): void {
    this.dropdownContent = {
      ...this.dropdownContent,
      anotherServices: {
        ...this.dropdownContent.anotherServices,
        content: otherServicesList.map((item: EcosystemMenuItem) => this.transformListItemForUIKit(item))
      },
      popularServices: {
        ...this.dropdownContent.popularServices,
        content: popularServicesList.map((item: EcosystemMenuItem) =>
          this.transformListItemForUIKit(item, { withContent: false })
        )
      }
    }
  }

  protected transformListItemForUIKit(
    item: EcosystemMenuItem,
    params = { withContent: true }
  ): EcosystemMenuItemUIKitFormat {
    return {
      id: item.id,
      link: item.link,
      name: item.name,
      image_1: this.$services.image.cropImage(item.image, {
        mode: 'auto'
      }),
      content: params.withContent ? item.content : ''
    }
  }

  public closeDropdown(): void {
    this.isShowDropdownMenu = false
  }

  public async showDropdown(): Promise<void> {
    this.isShowDropdownMenu = !this.isShowDropdownMenu
    if (this.isShowDropdownMenu) {
      const [otherServicesList, popularServicesList] = await Promise.all([
        this.$services.ecosystem.getOtherServicesMenuList<EcosystemMenuItem[]>(),
        this.$services.ecosystem.getPopularServicesMenuList<EcosystemMenuItem[]>()
      ])
      this.setDropdownContent(otherServicesList, popularServicesList)
    }
  }
}
