import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import {
  DigitalLectureDetailMaterialModel,
  DigitalLectureDetailSpeakerModel,
  DigitalLectureDetailTagModel
} from '~/model/DigitalLecture/index'
import NewsRecommendationCardModel from '~/model/Cards/NewsRecommendationCardModel'
import { toDateFormatComma } from '~/shared/utils/date'

export default class DigitalLectureDetailModel extends BaseModel {
  id: number = 0
  slug: string = ''
  name: string = ''
  'date_publication': string = ''
  preview: string = ''
  'video_link': string = ''
  'video_file': string = ''
  'short_description': string = ''
  description: string = ''
  'is_published': boolean = false
  'category_id': number = 0
  'likes_count': number = 0
  'is_liked': boolean = false
  'views_count': number = 0
  'created_at': string = ''
  'updated_at': string = ''
  tags: DigitalLectureDetailTagModel[] = []
  materials: DigitalLectureDetailMaterialModel[] = []
  speakers: DigitalLectureDetailSpeakerModel[] = []
  news: NewsRecommendationCardModel[] = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.tags)) {
        this.tags = data.tags.map(i => new DigitalLectureDetailTagModel(i))
      }

      if (Array.isArray(data.materials)) {
        this.materials = data.materials.map(i => new DigitalLectureDetailMaterialModel(i))
      }

      if (Array.isArray(data.speakers)) {
        this.speakers = data.speakers.map(i => new DigitalLectureDetailSpeakerModel(i))
      }

      if (Array.isArray(data.news)) {
        this.news = data.news.map(i => new NewsRecommendationCardModel(i))
      }
    }

    if (this.date_publication) {
      this.date_publication = toDateFormatComma(this.date_publication)
    }
  }
}
