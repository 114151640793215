import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { EventScheduleItemModel } from '~/model/EventSchedule'

@Module({ namespaced: true, stateFactory: true, name: 'eventSchedule' })
export default class extends VuexModule {
  private eventScheduleList: EventScheduleItemModel[] = []

  @Mutation
  private mutationSetEventScheduleList(list: EventScheduleItemModel[]): void {
    this.eventScheduleList = list
  }

  @Action
  public setEventScheduleList(list: EventScheduleItemModel[]): void {
    this.context.commit('mutationSetEventScheduleList', list)
  }

  get EventScheduleList() {
    return this.eventScheduleList
  }
}
